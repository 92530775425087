import React, { useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Loginpage from "./pages/login/loginpage";
import Dashboard from "./pages/dashboard/dashboard";
import AccDashboard from "./pages/accDashbaoard/accDashboard";
import AccProfile from "./pages/accProfile/AccProfile";
import Directory from "./pages/Directory";
import SingleDirectory from "./pages/Directory/singleDirectory/SingleDirectory";
import MallProduct from "./pages/dashboard/MallProduct/MallProduct";
import Transactions from "./pages/Transactions";
import HomePage from "./pages/HomePage";
import { BankContext } from "./context/Context";
import ServicePage from "./pages/ServicePage";
import SingleService from "./pages/ServicePage/SingleService";

function MyRoutes() {
  const { pin } = useContext(BankContext);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/homepage"
          // element={pin === "4141" ? <LandingPage /> : <HomePage />}
          element={<LandingPage />}
        />
        <Route
          path="/login"
          // element={pin === "4141" ? <Loginpage /> : <HomePage />}
          element={<Loginpage />}
        />
        <Route
          path="/dashboard/users"
          // element={pin === "4141" ? <Dashboard /> : <HomePage />}
          element={<Dashboard />}
        />
        <Route
          path="/dashboard/accountants"
          // element={pin === "4141" ? <AccDashboard /> : <HomePage />}
          element={<AccDashboard />}
        />
        <Route
          path="/vault/:coinType/:appCode/:coinSymbol"
          // element={pin === "4141" ? <Transactions /> : <HomePage />}
          element={<Transactions />}
        />
        <Route
          path="/dashboard/accountants/profile"
          // element={pin === "4141" ? <AccProfile /> : <HomePage />}
          element={<AccProfile />}
        />
        <Route
          path="/directory"
          // element={pin === "4141" ? <Directory /> : <HomePage />}
          element={<Directory />}
        />
        <Route
          path="/directory/:id"
          // element={pin === "4141" ? <SingleDirectory /> : <HomePage />}
          element={<SingleDirectory />}
        />
        <Route
          path="/dashboard/users/:id"
          // element={pin === "4141" ? <MallProduct /> : <HomePage />}
          element={<MallProduct />}
        />
        <Route path="/services/:id" element={<SingleService />} />
        <Route path="/services" element={<ServicePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
