import React, { useContext } from 'react';
// import classNames from '../../../pages/VaultsPage/vaultsPage.module.scss';
import { VaultContents } from './VaultContents';
// import searchIcon from '../../../static/images/search.svg';
// import vault from '../../../static/images/vaults/fulllogo.svg';
// import { NetWorthContext } from '../../../context/NetWorthContext';
// import { useNavigate } from "react-router-dom";
// import { useStore } from '../../../components/store/store'; 

export const VaultComponent = () => {

  return (
    <>
      <VaultContents />
    </>
  );
};
