import { Fragment } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import Routes from "./Routes";
import AppContextProvider from "./context/AppContext";
import { CoinContextProvider } from "./context/CoinContext";
import VaultPageContextProvider from "./context/VaultPageContext";
import BankContextProvider from "./context/Context";
import { GlobalContexProvider } from "./pages/dashboard/WalletScan/globalContext";
import ContextProvider from "./pages/dashboard/WalletScan/globalComponents/Context/Context";

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: true,
      },
    },
  });
  return (
    <Fragment>
      <QueryClientProvider client={queryClient}>
        <AppContextProvider>
          <BankContextProvider>
            <CoinContextProvider>
              <VaultPageContextProvider>
                <GlobalContexProvider>
                  <ContextProvider>
                    <Routes />
                  </ContextProvider>
                </GlobalContexProvider>
              </VaultPageContextProvider>
            </CoinContextProvider>
          </BankContextProvider>
        </AppContextProvider>
      </QueryClientProvider>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
