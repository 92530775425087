import React, {
  useContext,
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import "./howitworks.scss";
// import NavBar from "../../components/NavBar";
// import "./Homepage.scss";
import { useNavigate } from "react-router-dom";

// import BranchIcon from "../../assets/images/logos/branchicon.png";
// import DownArrow from "../../assets/images/logos/downarrow1.png";
// import LocationIcon from "../../assets/images/logos/locationicon.png";
// import InstaLegalIcon from "../../assets/images/logos/legalicon.svg";
// import { GlobalContex } from "../../globalContext";
// import MobileMenu from "../MobileMenu";

//images
import threeDotsHorizontal from "../../static/images/threeDotsHorizontal.svg";
import phone from "../../static/images/phone.svg";
import phoneRight from "../../static/images/phoneRight.svg";
import playStoreIcon from "../../static/images/playStoreIcon.svg";
import appStoreIcon from "../../static/images/appStoreIcon.svg";
import individualIcon from "../../static/images/individualIcon.svg";
import dropIcon from "../../static/images/dropIcon.svg";
import locationIcon from "../../static/images/locationIcon.svg";
import tickIcon from "../../static/images/tick.svg";
import nav1 from "../../static/images/nav1.svg";
import nav2 from "../../static/images/nav2.svg";
import nav3 from "../../static/images/nav3.svg";
import nav4 from "../../static/images/nav4.svg";
import nav5 from "../../static/images/nav5.svg";
import { useStore } from "../../components/store/store";
import Dropdown from "../../components/DropDown/dropdown";
import mobphone from "../../static/images/mobphone.svg";
import MobMenu from "../../components/mobMenu/mobMenu";
import ScrollSpy from "react-ui-scrollspy";
import { BankContext } from "../../context/Context";
// import homeImage from "../../static/images/logos/RightLogo.svg";

// const HowItWorks = () => {
//   const navigate = useNavigate();
//   // const { showMobileMenu, setShowMobileMenu, setSelectedApp, globalMenu } =
//   //   useContext(GlobalContex);
//   // return !showMobileMenu ? (
//   return (
//     <>
//      <div className="mainBg}>
//       <div>
//         <p className="mainText}>We Give Accountants Across The World The Power Of AI</p>
//       </div>
//       <div className="btnGroup}>
//         <p className="btn1}>Get Three Quotes</p>
//         <p className="btn2}>Talk To Our AI</p>
//       </div>
//      </div>
//      {/* <div className="bottomCard}>
//        <div className= "card1}>

//        </div>
//      </div> */}
//     </>
//   )
// };

const scrollMenu = [
  {
    id: "first",
    image: nav1,
    title: "Individuals",
  },
  {
    id: "second",
    image: nav2,
    title: "Business Owners",
  },
  {
    id: "third",
    image: nav3,
    title: "Accountants",
  },
  // {
  //   id: "fourth",
  //   image: nav4,
  //   title: "Financial Professionals",
  // },
  // {
  //   id: "fifth",
  //   image: nav5,
  //   title: "Signature Certified Consultants",
  // },
];

const HowItWorks = () => {
  const {
    isOpen,
    setOpen,
    mobsize,
    setmobsize,
    ismobnav,
    setmobnav,
    mobMenuOpen,
    scrollmenu,
    setscrollmenu,
  } = useStore();
  const { searchTerm, setSearchTerm, searchDirectory, setSearchDirectory } =
    useContext(BankContext);
  const navigate = useNavigate();
  const [show, setShow] = useState("none");
  const [scroll, setscroll] = useState(0);
  // const { showMobileMenu, setShowMobileMenu, setSelectedApp, globalMenu } =
  //   useContext(GlobalContex);

  // const allnavref = useRef(null);
  const nav1ref = useRef(null);
  const nav2ref = useRef(null);
  const nav3ref = useRef(null);
  const nav4ref = useRef(null);
  const nav5ref = useRef(null);

  const handleView = (name) => {
    setscrollmenu(name);
    if (name === "Individuals") {
      nav1ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else if (name === "Business Owners") {
      nav2ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else if (name === "Accountants") {
      nav3ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else if (name === "Financial Professionals") {
      nav4ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    } else if (name === "Signature Certified Consultants") {
      nav5ref.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShow("");
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      setmobsize(window.innerWidth);
      if (window.innerWidth < 900) {
        setOpen(false);
        setmobnav(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, [mobsize]);

  const handleScroll = (e, val) => {
    console.log(e);
    // if(e.target.scrollTop > 0){
    //   setscroll(true)
    // }
    // else{
    //   setscroll(false)
    // }
  };

  const handleServiceInput = (e) => {
    setSearchTerm(e.target.value);
    navigate("/services");
  };
  const handleDirectoryInput = (e) => {
    setSearchDirectory(e.target.value);
    navigate("/directory");
  };

  return (
    <>
      {/* <NavBar /> */}
      <div>
        {isOpen ? (
          <div className="dropContainer">
            <Dropdown />
          </div>
        ) : (
          <div className="wholeContainer">
            <div className="topHalf">
              <div className="leftHalfImage">
                <div>
                  <div className="title">Ai Powered</div>
                  <div className="subtitle">Accounting Is Here</div>
                </div>
                {/* <div className="subtitle">& Manage Your Investor Relations</div> */}
                {/* <div className="buttonWrapper">
              <div className="colorButton">Accountant Directory</div>
              <div className="colorButton">Automate Tax & Financial Planning</div>
              <div className="colorButton">Instant Filings</div>
              <div className="colorButton">Crypto Taxes</div>
              <div className="colorButton">See All Features</div>
            </div> */}
                {/* <div className="storeIcons">
              <img src={appStoreIcon} alt="appStoreIcon" className="appStoreIcon" />
              <div className="vl"></div>
              <img src={playStoreIcon} alt="playStoreIcon" className="playStoreIcon" />
            </div> */}
                <div className="databox">
                  <div className="questionbox">
                    <div className="qtitle">
                      Search For The Service You Need
                    </div>
                    <div className="qinputbox">
                      <input
                        className="qinput"
                        placeholder="Ex. Income tax filings"
                        value={searchTerm}
                        onChange={handleServiceInput}
                      />
                    </div>
                  </div>
                  <div className="dividerbox">
                    <div className="dividerline"></div>
                    <div className="dividertext">OR</div>
                    <div className="dividerline"></div>
                  </div>
                  <div className="questionbox">
                    <div className="qtitle">Find Your Accountant</div>
                    <div className="qinputbox">
                      <input
                        className="qinput"
                        placeholder="Search Accountant By Name"
                        value={searchDirectory}
                        onChange={handleDirectoryInput}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="rightHalfImage">
                <img
                  src={phoneRight}
                  alt="phone"
                  className="phoneRight"
                  style={{ display: show, height: scroll ? "65vh" : "" }}
                />
              </div>
            </div>
            {/* <div className="bottomHalf">
              <div className="bottomTitle">
                Find The Perfect Accountant In Minutes
              </div>
              <div className="formGrid">
                <div className="inputWrapper">
                  <input
                    className="inputStyle"
                    type="text"
                    placeholder="What Service Are You Looking For?"
                  />
                </div>
                <div className="findBtn">
                  <div className="inputStyle1">Find Accountants</div>
                </div>
                <div className="findBtn">
                  <div className="inputStyle2">Ask AI</div>
                </div>
              </div>
            </div> */}
            <div className="secondSection">
              {/* <p className="about">
                What Does&nbsp;
                <span style={{ fontWeight: 700 }}>Accountants.io</span>&nbsp;Do?
              </p>
              <div className="groupBtns">
                <p className="aboutBtn">For Individuals</p>
                <p className="aboutBtn" style={{ opacity: 0.25 }}>
                  Investors
                </p>
                <p className="aboutBtn" style={{ opacity: 0.25 }}>
                  Business Owners
                </p>
              </div>
              <p className="pointsText">Porftolio.ai</p>
              <div>
                <div className="points">
                  <div className="pointsIcon">
                    <img
                      src={tickIcon}
                      alt="tickIcon"
                      className="tickIcon"
                    />
                    <p className="pointsNote">
                      Provide access to Crypto/INR markets{" "}
                    </p>
                  </div>
                  <div className="pointsIcon">
                    <img
                      src={tickIcon}
                      alt="tickIcon"
                      className="tickIcon"
                    />
                    <p className="pointsNote">
                      Invest into ShareTokens of Indian startups{" "}
                    </p>
                  </div>
                  <div className="pointsIcon">
                    <img
                      src={tickIcon}
                      alt="tickIcon"
                      className="tickIcon"
                    />
                    <p className="pointsNote">
                      Track all your fiat and crypto assets in one place{" "}
                    </p>
                  </div>
                  <div className="pointsIcon">
                    <img
                      src={tickIcon}
                      alt="tickIcon"
                      className="tickIcon"
                    />
                    <p className="pointsNote">
                      Earn Interest With INR & Crypto Bonds{" "}
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="allscrollmenu">
                {/* <Scrollspy  items={ ['first','second', 'third', 'fourth', 'fifth'] }> */}
                {scrollMenu.map((each, i) => (
                  <>
                    {/* <li onClick={() => handleView(each.title)} id={each.title.substring(0, 2)}
                 
                  ><a href={`#${each.id}`}> */}
                    <div
                      data-to-scrollspy-id={each.id}
                      className={
                        each.title === scrollmenu
                          ? `eachscrollmenu active-scroll-spy`
                          : "eachscrollmenu"
                      }
                      // style={{
                      //   borderBottomWidth: each.title === scrollmenu ? "4px" : "",
                      //   borderBottomStyle:
                      //     each.title === scrollmenu ? "solid" : "",
                      //   borderImage:
                      //     "linear-gradient(90deg, #59A2DD 4.23%, #186AB4 100%) 1",
                      // }}
                      onClick={() => handleView(each.title)}
                    >
                      <div className="scrollimgbox">
                        <img className="scrollimg" src={each.image} alt="" />
                      </div>
                      <div className="srolltitle">{each.title}</div>
                    </div>
                    {/* </a></li> */}
                  </>
                ))}
                {/* </Scrollspy> */}
              </div>
              {/* <div className="scrollData">
              <div className="scrollData1" onScroll={(e) => handleScroll(e, 0)} ref={allnavref}> */}
              <div className="scrollData">
                <ScrollSpy>
                  {/* <section id="first"> */}
                  <div
                    id="first"
                    className="eachscroll"
                    onScroll={(e) => handleScroll(e, 0)}
                    ref={nav1ref}
                  >
                    <div className="scrollbox1">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Discover{" "}
                          <span className="scrollbox1title2">Accountants</span>
                        </div>
                        <div className="scrollbox1desc">
                          An intuitive discovery tool that helps users easily
                          find and connect with qualified accountants tailored
                          to their specific needs.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Synchronize{" "}
                          <span className="scrollbox1title2">Transactions</span>
                        </div>
                        <div className="scrollbox1desc">
                          A comprehensive synchronization feature that allows
                          users to effortlessly manage and update transactions
                          across multiple bank accounts in real-time.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbox2">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Manage <span className="scrollbox1title2">Tasks</span>
                        </div>
                        <div className="scrollbox1desc">
                          An integrated task management feature that enables
                          seamless collaboration and task tracking between users
                          and their accountants.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Share{" "}
                          <span className="scrollbox1title2">
                            Files Securely
                          </span>
                        </div>
                        <div className="scrollbox1desc">
                          A secure file-sharing feature that ensures users can
                          safely exchange documents and files with their
                          accountant with end-to-end encryption.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbtns">
                      <div
                        className="scrollbtn1"
                        onClick={() => {
                          window.open(
                            "https://finace.io/registration",
                            "_self"
                          );
                        }}
                      >
                        Get Started For Free
                      </div>
                      <div
                        className="scrollbtn2"
                        onClick={() => navigate("/directory")}
                      >
                        Discover Accountants
                      </div>
                    </div>
                  </div>
                  {/* </section> */}
                  {/* <section id="second"> */}
                  <div
                    id="second"
                    className="eachscroll"
                    onScroll={(e) => handleScroll(e, 1)}
                    ref={nav2ref}
                  >
                    <div className="scrollbox1">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Discover{" "}
                          <span className="scrollbox1title2">Accountants</span>
                        </div>
                        <div className="scrollbox1desc">
                          An intuitive discovery tool that helps users easily
                          find and connect with qualified accountants tailored
                          to their specific needs.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Manage{" "}
                          <span className="scrollbox1title2">Payments</span>
                        </div>
                        <div className="scrollbox1desc">
                          A convenient payment system that allows users to
                          easily and securely pay their accountants directly
                          through the platform.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbox2">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Place <span className="scrollbox1title2">Bids</span>
                        </div>
                        <div className="scrollbox1desc">
                          A unique task creation feature that lets users post
                          accounting tasks and receive competitive bids from
                          qualified accountants.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Share{" "}
                          <span className="scrollbox1title2">
                            Files Securely
                          </span>
                        </div>
                        <div className="scrollbox1desc">
                          A secure file-sharing feature that ensures users can
                          safely exchange documents and files with their
                          accountant with end-to-end encryption.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbtns">
                      <div
                        className="scrollbtn1"
                        onClick={() => {
                          window.open(
                            "https://finace.io/registration",
                            "_self"
                          );
                        }}
                      >
                        Get Started For Free
                      </div>
                      <div
                        className="scrollbtn2"
                        onClick={() => navigate("/directory")}
                      >
                        Discover Accountants
                      </div>
                    </div>
                  </div>
                  {/* </section> */}
                  {/* <section id="third"> */}
                  <div
                    id="third"
                    className="eachscroll"
                    onScroll={(e) => handleScroll(e, 2)}
                    ref={nav3ref}
                  >
                    <div className="scrollbox1">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Sell Your{" "}
                          <span className="scrollbox1title2">Gigs</span>
                        </div>
                        <div className="scrollbox1desc">
                          A marketplace feature that enables accountants to list
                          and sell their services as gigs, allowing users to
                          browse and purchase professional accounting solutions.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Participate In{" "}
                          <span className="scrollbox1title2">Bids</span>
                        </div>
                        <div className="scrollbox1desc">
                          A bidding platform that allows accountants to
                          competitively bid on service requests posted by users,
                          offering their expertise for various accounting tasks.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbox2">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Manage Your{" "}
                          <span className="scrollbox1title2">Clients</span>
                        </div>
                        <div className="scrollbox1desc">
                          A client management tool, streamlining communication,
                          scheduling, and tracking of client engagements and
                          financial interactions.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          Share Your{" "}
                          <span className="scrollbox1title2">Profile</span>
                        </div>
                        <div className="scrollbox1desc">
                          Showcase your expertise and credentials by easily
                          sharing your professional profiles with potential
                          clients or employers.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbtns">
                      <div
                        className="scrollbtn1"
                        onClick={() => {
                          window.open(
                            "https://finace.io/registration",
                            "_self"
                          );
                        }}
                      >
                        Get Started For Free
                      </div>
                    </div>
                  </div>
                  {/* </section> */}
                  {/* <section id="fourth"> */}
                  {/* <div
                    id="fourth"
                    className="eachscroll"
                    onScroll={(e) => handleScroll(e, 3)}
                    ref={nav4ref}
                  >
                    <div className="scrollbox1">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with
                          
                          your money. Ria makes it easy for your
                          
                          to track what you send.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with
                          
                          your money. Ria makes it easy for your
                          
                          to track what you send.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbox2">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with
                         
                          your money. Ria makes it easy for your
                         
                          to track what you send.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with your money. Ria
                          makes it easy for your to track what you send.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbtns">
                      <div className="scrollbtn1">Get Started For Free</div>
                      <div className="scrollbtn2">See Plans & Pricing</div>
                    </div>
                  </div> */}
                  {/* </section> */}
                  {/* <section id="fifth"> */}
                  {/* <div
                    id="fifth"
                    className="eachscroll"
                    onScroll={(e) => handleScroll(e, 4)}
                    ref={nav5ref}
                  >
                    <div className="scrollbox1">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with your money. Ria
                          makes it easy for your to track what you send.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with your money. Ria
                          makes it easy for your to track what you send.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbox2">
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with your money. Ria
                          makes it easy for your to track what you send.
                        </div>
                      </div>
                      <div className="scrollboxeach">
                        <div className="scrollbox1title1">
                          File Your{" "}
                          <span className="scrollbox1title2">Taxes</span>
                        </div>
                        <div className="scrollbox1desc">
                          Stay informed of what’s happening with your money. Ria
                          makes it easy for your to track what you send.
                        </div>
                      </div>
                    </div>
                    <div className="scrollbtns">
                      <div className="scrollbtn1">Get Started For Free</div>
                      <div className="scrollbtn2">See Plans & Pricing</div>
                    </div>
                  </div> */}
                  {/* </section> */}
                </ScrollSpy>
              </div>
              {/* </div>
              </div> */}
            </div>
            <>
              {!mobMenuOpen ? (
                <div className="mobtopHalf">
                  <>
                    <div className="mobfirstsec">
                      <div className="mobtitle">Ai Powered</div>
                      <div className="mobsubtitle">Accounting Is Here</div>
                      {/* <div className="playboxes}>
                <div className="gstorebox}>
                  <img className="gstore} src={playStoreIcon} alt="" />
                </div>
                <div className="astorebox}>
                  <img className="astore} src={appStoreIcon} alt="" />
                </div>
              </div> */}
                    </div>
                    <div className="mobphonebox">
                      <img className="mobphone" src={phoneRight} alt="" />
                    </div>
                    {/* <div className="storebox">
                  <div className="googlestorebox">
                    <img
                      className="googlestore"
                      src={playStoreIcon}
                      alt=""
                    />
                  </div>
                  <div className="linesbox"> </div>
                  <div className="applestorebox">
                    <img
                      className="applestore"
                      src={appStoreIcon}
                      alt=""
                    />
                  </div>
                </div> */}
                  </>
                </div>
              ) : (
                <>
                  <MobMenu />
                </>
              )}
            </>

            {/* <div className="bottomHalfMobile}>
          <div className="bottomTitle}>Find The Perfect Accountant In Minutes</div>
          <div className="formGrid}>
            <div className="inputWrapper}>
              <input
                className="inputStyle}
                type="text"
                placeholder="What Service Are You Looking For?"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="inputWrapper}
                style={{ paddingRight: "10px" }}
              >
                <img
                  src={threeDotsHorizontal}
                  alt="threeDotsHorizontal"
                  className="icons}
                />
                <input
                  className="inputStyle}
                  type="text"
                  placeholder="For Individuals"
                />
              </div>
              <div className="inputWrapper}>
                <img src={locationIcon} alt="location" className="icons} />
                <input
                  className="inputStyle}
                  type="text"
                  placeholder="Select City"
                />
              </div>
            </div>
            <div className="inputWrapper1}>
              <div className="inputStyle1}>Submit</div>
            </div>
          </div>
        </div> */}
          </div>
        )}
      </div>
    </>
  );
};

export default HowItWorks;
