import React, { useEffect, useRef, useState } from "react";
import classNames from "./custom.module.scss";

import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { LiaSearchSolid } from "react-icons/lia";
import { GoPlus } from "react-icons/go";

const CustomInput = ({
  title,
  placeholder,
  stateValue,
  setState,
  name,
  type,
  func,
  subTitle,
  denomination,
  divider,
  currency,
}) => {
  const [inputValue, setInputValue] = useState("");

  return (
    <div className={classNames.customInput}>
      {title && (
        <div
          className={classNames.title}
          style={{ margin: subTitle ? "5px" : "" }}
        >
          {title}
        </div>
      )}
      {subTitle && <div className={classNames.subTitle}>{subTitle}</div>}
      <div className={classNames.denominationContainer}>
        {currency && <div className={classNames.currency}>$</div>}
        <input
          type="text"
          placeholder={placeholder}
          style={{
            width: denomination ? "calc(100% - 4rem)" : "",
            maxWidth: denomination ? "calc(100% - 4rem)" : "",
            minWidth: denomination ? "calc(100% - 4rem)" : "",
            padding: currency ? "1.1rem 2.2rem" : "",
          }}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event?.target?.value };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              func(event, name ? stateValue[name] : stateValue);
              if (name) {
                setState((prev) => ({
                  ...prev,
                  [name]: "",
                }));
              } else {
                setState("");
              }
            }
          }}
        />
        {denomination && (
          <div className={classNames.denomination}>{denomination}</div>
        )}
      </div>
      {divider && <div className={classNames.divider}></div>}
    </div>
  );
};

export default CustomInput;

export const CustomInputExist = ({
  title,
  placeholder,
  stateValue,
  setState,
  name,
  type,
  func,
  subTitle,
  denomination,
  divider,
  currency,
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (name && stateValue[name] !== undefined) {
      setInputValue(stateValue[name]);
    } else if (!name) {
      setInputValue(stateValue);
    } else {
      setInputValue("");
    }
  }, [name, stateValue]);

  return (
    <div className={classNames.customInput} style={{ pointerEvents: "none" }}>
      {title && (
        <div
          className={classNames.title}
          style={{ margin: subTitle ? "5px" : "" }}
        >
          {title}
        </div>
      )}
      {subTitle && <div className={classNames.subTitle}>{subTitle}</div>}
      <div className={classNames.denominationContainer}>
        <input
          type="text"
          placeholder={placeholder}
          style={{
            width: denomination ? "calc(100% - 4rem)" : "",
            maxWidth: denomination ? "calc(100% - 4rem)" : "",
            minWidth: denomination ? "calc(100% - 4rem)" : "",
            color: Number(inputValue) < 0 ? "#FE2C55" : "",
          }}
          value={currency ? currency + inputValue : inputValue}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              func(event, name ? stateValue[name] : stateValue);
              if (name) {
                setState((prev) => ({
                  ...prev,
                  [name]: "",
                }));
              } else {
                setState("");
              }
            }
          }}
        />
        {denomination && (
          <div className={classNames.denomination}>{denomination}</div>
        )}
      </div>
      {divider && <div className={classNames.divider}></div>}
    </div>
  );
};

export const CustomDropdown = ({
  title,
  dropdown,
  name,
  stateValue,
  setState,
  topTitle,
  type,
  stateVal,
  mapVal,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !dropdownRef?.current?.contains(event?.target) &&
        !inputRef?.current?.contains(event?.target)
      ) {
        setIsOpen(false);
      }
    };

    const handleDocumentClick = (event) => {
      handleClickOutside(event);
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const selectOption = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    // Perform any additional actions based on the selected option
  };

  useEffect(() => {
    if (name && stateValue[name]) {
      setSelectedOption(stateValue[name]);
    } else if (typeof stateValue !== "object" && stateValue) {
      setSelectedOption(stateValue);
    }
  }, [stateValue]);

  return (
    <div
      className={classNames.eachCustomDropdown}
      style={{
        // minWidth: isOpen ? "250px" : "",
        zIndex: isOpen ? "3" : "",
        height: topTitle ? "5.2rem" : "4.5rem",
      }}
    >
      {topTitle && <div className={classNames.topTitle}>{title}</div>}
      <div
        className={classNames.inputContainer}
        onClick={toggleDropdown}
        style={{
          pointerEvents: dropdown ? "" : "none",
          top: topTitle ? "2rem" : "",
          maxHeight: isOpen ? "400px" : "100%",
          borderBottom: "",
        }}
        ref={dropdownRef}
      >
        <span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              gap: "15px",
              opacity: selectedOption ? "" : "0.5",
            }}
          >
            {name && stateValue?.Icon ? (
              <img
                src={stateValue?.Icon}
                alt={stateValue?.NameOfTeam}
                style={{ height: "1.25rem" }}
              />
            ) : (
              ""
            )}
            {selectedOption
              ? selectedOption
              : title
              ? title
              : placeholder
              ? placeholder
              : "Select an option"}
          </span>
          <span>{isOpen ? <IoMdArrowDropup /> : <IoMdArrowDropdown />}</span>
        </span>
        {isOpen && (
          <ul className="dropdown-list">
            {dropdown?.length > 3 && isOpen && (
              <li
                style={{
                  display: dropdown?.length <= 3 ? "none" : "",
                }}
              >
                <input
                  ref={inputRef}
                  type="text"
                  placeholder="Search.."
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                  onClick={(event) => event.stopPropagation()}
                />
              </li>
            )}
            {type === "obj2Names" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(
                            eachitem[mapVal?.name] +
                              " (" +
                              eachitem[mapVal?.name1] +
                              ")"
                          );
                          setState({
                            ...stateValue,
                            [name]: eachitem[stateVal],
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {eachitem[mapVal?.name] +
                          " (" +
                          eachitem[mapVal?.name1] +
                          ")"}
                      </li>
                    );
                  })
              : type === "obj" && dropdown?.length > 0
              ? dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const values = Object?.values(row);
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search

                    return values?.some((value) => {
                      if (typeof value === "string") {
                        return value?.toLowerCase()?.includes(searchQueryy);
                      }
                      return false;
                    });
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem[mapVal?.name]);
                          setState({
                            ...stateValue,
                            [name]: eachitem[stateVal],
                          });
                        }}
                        key={eachitem[mapVal?.name] + index}
                      >
                        {eachitem[mapVal?.image] && (
                          <img
                            src={eachitem[mapVal?.image]}
                            alt="dropdownimage"
                          />
                        )}
                        {eachitem[mapVal?.name]}
                      </li>
                    );
                  })
              : title === "All Countries"
              ? dropdown?.length > 0 &&
                dropdown
                  ?.filter((row) => {
                    // Convert the row object values to an array and check if any value matches the search query
                    const searchQueryy = searchQuery?.toLowerCase(); // Convert the search query to lowercase for a case-insensitive search
                    return row?.toLowerCase()?.includes(searchQueryy);
                  })
                  ?.map((eachitem, index) => {
                    return (
                      <li
                        onClick={(event) => {
                          event.stopPropagation();
                          selectOption(eachitem);
                          setState(eachitem);
                        }}
                        key={eachitem + index}
                        style={{
                          display: eachitem === stateValue ? "none" : "",
                        }}
                      >
                        {eachitem}
                      </li>
                    );
                  })
              : (name === "Gender" ||
                  name === "groupType" ||
                  type === "single") &&
                dropdown?.length > 0
              ? dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem);
                        setState({
                          ...stateValue,
                          [name]: eachitem,
                        });
                      }}
                      key={eachitem + index}
                    >
                      {eachitem}
                    </li>
                  );
                })
              : dropdown?.length > 0 &&
                dropdown?.map((eachitem, index) => {
                  return (
                    <li
                      onClick={(event) => {
                        event.stopPropagation();
                        selectOption(eachitem?.NameOfTeam);
                        if (name === "teamUsername") {
                          setState((prev) => {
                            return { ...prev, [name]: eachitem?.TeamUsername };
                          });
                        } else {
                          setState({
                            ...stateValue,
                            [name]: {
                              NameOfTeam: eachitem?.NameOfTeam,
                              Icon: eachitem?.Icon,
                            },
                          });
                        }
                      }}
                      key={eachitem?.NameOfTeam + index}
                    >
                      <img src={eachitem?.Icon} alt={eachitem?.NameOfTeam} />
                      {eachitem?.NameOfTeam}
                    </li>
                  );
                })}
          </ul>
        )}
      </div>
    </div>
  );
};

export const CustomTextarea = ({
  title,
  placeholder,
  stateValue,
  setState,
  name,
}) => {
  return (
    <div className={classNames.customInput}>
      {title && <div className={classNames.title}>{title}</div>}
      <textarea
        placeholder={placeholder}
        onChange={(event) => {
          if (name) {
            console.log(event?.target?.value, "Text area");
            setState((prev) => {
              return { ...prev, [name]: event?.target?.value };
            });
          } else {
            setState(event?.target?.value);
          }
        }}
      />
    </div>
  );
};

export const CustomSearchbox = ({
  title,
  placeholder,
  stateValue,
  setState,
  name,
}) => {
  return (
    <div className={classNames.customInput}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.inputContainer}>
        <input
          type="text"
          placeholder={placeholder}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event?.target?.value };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
        />
        <LiaSearchSolid />
      </div>
    </div>
  );
};

export const EachCustomSelectMultiple = ({
  title,
  placeholder,
  name,
  stateValue,
  setState,
  dropdown,
  filterQuery,
}) => {
  const [selectedValue, setSelectedvalue] = useState([]);

  //functions
  const toggleSelection = (prev, item) => {
    return prev.includes(item)
      ? prev.filter((i) => i !== item)
      : [...prev, item];
  };

  return (
    <div className={classNames.eachInputSelectOne}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.optionParent}>
        {dropdown?.length > 0 &&
          dropdown
            .filter((eachItem) =>
              eachItem?.toLowerCase().includes(filterQuery?.toLowerCase())
            )
            .map((eachItem, index) => (
              <div
                className={`${classNames.eachOption} ${
                  selectedValue?.includes(eachItem)
                    ? classNames.selectedOption
                    : ""
                }`}
                key={eachItem + index}
                onClick={() => {
                  if (name) {
                    setSelectedvalue((prev) => toggleSelection(prev, eachItem));
                    setState((prev) => ({
                      ...prev,
                      [name]: toggleSelection(prev[name] || [], eachItem),
                    }));
                  } else {
                    setState((prev) => toggleSelection(prev, eachItem));
                    setSelectedvalue((prev) => toggleSelection(prev, eachItem));
                  }
                }}
              >
                {eachItem}
                <GoPlus />
              </div>
            ))}
      </div>
    </div>
  );
};

export const CustomSelectBox = ({
  title,
  placeholder,
  stateValue,
  setState,
  name,
  dropdown,
  info,
}) => {
  return (
    <div className={classNames.customSelectBox}>
      {title && (
        <div
          className={classNames.title}
          style={{ marginBottom: info ? "5px" : "" }}
        >
          {title}
        </div>
      )}
      {info && <div className={classNames.info}>{info}</div>}
      <div className={classNames.allBoxes}>
        {dropdown?.length > 0 &&
          dropdown?.map((eachBox, index) => {
            return (
              <div
                key={eachBox?.title + index}
                className={`${classNames.eachBox} ${
                  stateValue[name] &&
                  stateValue[name] === eachBox?.value &&
                  classNames.selectedBox
                }`}
                onClick={() => {
                  if (name) {
                    setState((prev) => {
                      return { ...prev, [name]: eachBox?.value };
                    });
                  }
                }}
              >
                {eachBox?.image && (
                  <img src={eachBox?.image} alt={eachBox?.title} />
                )}
                {eachBox?.title && (
                  <div className={classNames.title}>{eachBox?.title}</div>
                )}
                {eachBox?.desc && (
                  <div className={classNames.desc}>
                    {eachBox?.desc}
                    {eachBox?.info && (
                      <span className={classNames.info}>{eachBox?.info}</span>
                    )}
                  </div>
                )}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export const EachCustomCheckBox = ({
  title,
  placeholder,
  stateValue,
  setState,
  name,
  dropdown,
}) => {
  return (
    <div className={classNames.eachCustomCheckbox}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.checkBoxContainer}>
        {dropdown?.length > 0 &&
          dropdown.map((eachItem, index) => {
            return (
              <label
                key={eachItem + index}
                onClick={() => {
                  setState((prev) => {
                    return { ...prev, [name]: eachItem };
                  });
                }}
              >
                <input
                  type="radio"
                  className={classNames.radioAsCheckbox}
                  name={name}
                  checked={stateValue[name] === eachItem}
                  onChange={() => {}}
                />
                <span></span>
                <span>{eachItem}</span>
              </label>
            );
          })}
      </div>
    </div>
  );
};

export const CustomInputDivided = ({
  title,
  placeholder,
  secondValue,
  stateValue,
  setState,
  name,
}) => {
  return (
    <div className={classNames.customInputDivider}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.dividerContainer}>
        <input
          type="text"
          placeholder={placeholder}
          onChange={(event) => {
            if (name) {
              setState((prev) => {
                return { ...prev, [name]: event?.target?.value };
              });
            } else {
              setState(event?.target?.value);
            }
          }}
        />
        <div className={classNames.secondValue}>{secondValue}</div>
      </div>
    </div>
  );
};

export const EachCustomCheckboxMultiple = ({
  title,
  options,
  stateValue,
  setState,
  name,
}) => {
  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    const checked = event.target.checked;

    setState((prev) => {
      let updatedValues;
      if (checked) {
        updatedValues = [...(prev[name] || []), value]; // Add value if checked
      } else {
        updatedValues = (prev[name] || []).filter((item) => item !== value); // Remove value if unchecked
      }
      return { ...prev, [name]: updatedValues };
    });
  };

  return (
    <div className={classNames.eachCustomCheckbox}>
      {title && <div className={classNames.title}>{title}</div>}
      <div className={classNames.checkBoxContainer}>
        {options.map((option) => (
          <label key={option.value}>
            <input
              type="checkbox"
              value={option?.value ? option?.value : option?.label}
              checked={
                stateValue[name]?.includes(
                  option?.value ? option?.value : option?.label
                ) || false
              }
              onChange={handleCheckboxChange}
            />
            {option.label}
          </label>
        ))}
      </div>
    </div>
  );
};
