import React, { useState } from "react";
import realtorwhite from "../../static/images/dropdown/accounts.svg";
import "./dashsidebar.scss";
import { useStore } from "../store/store";
import { useNavigate } from "react-router-dom";

const sidebarMenu1 = [
  {
    id: 0,
    title: "Accountants",
  },
  // {
  //   id: 0,
  //   title: "Scanner",
  // },
  {
    id: 1,
    title: "Services",
  },
  {
    id: 5,
    title: "Assignments",
  },
  {
    id: 2,
    title: "Vaults",
  },
  // {
  //   id: 3,
  //   title: "Tasks",
  // },
  // {
  //   id: 4,
  //   title: "Calendar",
  // },
];

const sidebarMenu2 = [
  {
    id: 0,
    title: "Vaults",
  },
  // {
  //   id: 1,
  //   title: "Cabinet",
  // },
  {
    id: 2,
    title: "Accountants",
  },
];

const sidebarMenu3 = [
  {
    id: 0,
    title: "TaxGPT",
  },
  {
    id: 1,
    title: "Settings",
  },
];

const Dashsidebar = ({ isNotOnMainPage, setOpenRight }) => {
  const { sideNav, setsideNav, setBuy } = useStore();
  const navigate = useNavigate();
  return (
    <div className="dashboard-sidebar" style={{ overflow: "hidden" }}>
      <div className="dashboard-left">
        <img
          style={{ width: "8vw", marginLeft: "3vw" }}
          src={realtorwhite}
          alt=""
        />
      </div>
      <div style={{ overflowY: "scroll", height: "75vh", marginTop: "30px" }}>
        <div>
          {sidebarMenu1.map((each, i) => {
            return (
              <div
                className="each-sidenav"
                style={{
                  background: sideNav === each.title ? "#FFFFFF" : "",
                  color: sideNav === each.title ? "#1F304F" : "",
                  // paddingLeft: sideNav === each.title ? '20px' : '',
                  // boxShadow:
                  //   sideNav === each.title
                  //     ? "0px 2px 2px rgba(0, 0, 0, 0.25)"
                  //     : "",
                  fontWeight: sideNav === each.title ? "700" : "500",
                  borderRadius: sideNav === each.title ? "35px" : "",
                }}
                key={i}
                onClick={() => {
                  setsideNav(each.title);
                  if (isNotOnMainPage) {
                    navigate("/dashboard/users/");
                    setBuy("step1");
                  }
                }}
              >
                {each.title}
              </div>
            );
          })}
        </div>
        {/* <div className="sidebar-line"></div> */}
        {/* <div>
          {sidebarMenu2.map((ele, j) => {
            return (
              <div
                className="each-sidenav"
                style={{
                  background: sideNav === ele.title ? "#FFFFFF" : "",
                  color: sideNav === ele.title ? "#1F304F" : "",
                  paddingLeft: sideNav === ele.title ? "20px" : "",

                  borderRadius: sideNav === ele.title ? "35px" : "",
                }}
                key={j}
                onClick={() => setsideNav(ele.title)}
              >
                {ele.title}
              </div>
            );
          })}
        </div> */}
      </div>
      <div
        className="side-btn"
        style={{
          background: "#59A2DD",
          borderRadius: "35px",
          padding: "15px 0px",
          color: "#FFF",
          width: "15vw",
          textAlign: "center",
          position: "fixed",
          bottom: "20px",
          cursor: "pointer",
        }}
        onClick={() => {
          setOpenRight(true);
          setsideNav("NewAssignment");
        }}
      >
        New Assignment
      </div>
    </div>
  );
};

export default Dashsidebar;
