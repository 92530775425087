import React, { useContext, useEffect, useRef, useState } from "react";
import classNames from "../dashboard.module.scss";
import CustomInput, {
  CustomDropdown,
  CustomInputDivided,
  CustomSearchbox,
  CustomSelectBox,
  CustomTextarea,
  EachCustomCheckBox,
  EachCustomCheckboxMultiple,
  EachCustomSelectMultiple,
} from "../../../components/Custom";
import axios from "axios";
import { BankContext } from "../../../context/Context";
import clockIcon from "../../../static/images/icons/clock.svg";
import piggybankIcon from "../../../static/images/icons/piggybank.svg";
import { IoIosAttach, IoMdClose } from "react-icons/io";

const NewBidSidebar = ({ setCloseState, setLocalRefresh }) => {
  //constants

  const { email } = useContext(BankContext);

  const [newBid, setNewBid] = useState({});
  const [allCountry, setAllCountry] = useState([]);
  const [allCurrency, setAllCurrency] = useState([]);

  //functions

  async function getAllCountries() {
    try {
      let response = await axios.get(
        "https://comms.globalxchange.io/coin/vault/countries/data/get"
      );

      if (response?.data.countries?.length) {
        setAllCountry(response?.data.countries);
      }
      //   console.log(response, "Get all countries response");
    } catch (error) {
      console.log(error?.message, "Get all countries server error");
    }
  }

  async function getAllCurrency() {
    try {
      let response = await axios.get(
        "https://comms.globalxchange.io/coin/vault/get/all/coins?fiat=true"
      );

      if (response?.data.coins?.length) {
        setAllCurrency(response?.data.coins);
      }
      //   console.log(response, "Get all currency response");
    } catch (error) {
      console.log(error?.message, "Get all currency server error");
    }
  }

  async function submitBid() {
    newBid.bidcreatoremail = email;

    try {
      let response = await axios.post(
        "https://accountants.apimachine.com/bid/add",
        newBid
      );

      if (response?.data?.status) {
        setCloseState(false);
        setLocalRefresh((prev) => !prev);
      }
      //   console.log(response, "Submit bid response");
    } catch (error) {
      console.log(error?.message, "Submit bid error");
    }
    console.log(newBid, "Submit bid response");
  }

  //renderings

  useEffect(() => {
    getAllCountries();
    getAllCurrency();
  }, []);

  return (
    <>
      <CustomInput
        placeholder="Bid name..."
        name="name"
        setState={setNewBid}
        stateValue={newBid}
      />
      <CustomTextarea
        placeholder="Describe your bid..."
        name="description"
        setState={setNewBid}
        stateValue={newBid}
      />
      <CustomDropdown
        placeholder="Select country"
        name="country"
        setState={setNewBid}
        stateValue={newBid}
        dropdown={allCountry}
        type="obj"
        mapVal={{ name: "name", image: "image" }}
        stateVal="name"
      />
      <CustomDropdown
        placeholder="Select currency"
        name="currency"
        setState={setNewBid}
        stateValue={newBid}
        dropdown={allCurrency}
        type="obj"
        mapVal={{ name: "coinName", image: "coinImage" }}
        stateVal="coinSymbol"
      />
      <CustomInput
        placeholder="Max price"
        name="maxPrice"
        setState={setNewBid}
        stateValue={newBid}
      />
      <CustomInput
        placeholder="Max time"
        name="maxTime"
        setState={setNewBid}
        stateValue={newBid}
      />
      <CustomInput
        placeholder="Min time"
        name="minTime"
        setState={setNewBid}
        stateValue={newBid}
      />
      <button className={classNames.submitBtn} onClick={submitBid}>
        Submit Bid
      </button>
    </>
  );
};

export default NewBidSidebar;

export const NewAssignment2 = ({ newAssignment, setNewAssignment }) => {
  const [searchQuery, setSearchQuery] = useState("");

  // useEffect(() => {
  //   if (searchQuery) {
  //     setNewAssignment((prev) => {
  //       return { ...prev, skills: [searchQuery] };
  //     });
  //   }
  // }, [searchQuery]);

  return (
    <div className={classNames.newAssignment2}>
      <CustomSearchbox
        title="What skills are required for your assignment?"
        placeholder=""
        stateValue={searchQuery}
        setState={setSearchQuery}
      />
      <div className={classNames.info} style={{ marginBottom: "3rem" }}>
        *For the best result, add 3-5 skills
      </div>
      <EachCustomSelectMultiple
        placeholder="Gender"
        name="skills"
        stateValue={newAssignment}
        setState={setNewAssignment}
        dropdown={[
          "Bookkeeping",
          "Accounting Basics",
          "Bank Reconciliation",
          "Data Entry",
          "Balance Sheet",
          "Accounts Payable",
        ]}
        filterQuery={searchQuery}
      />
    </div>
  );
};

export const NewAssignment3 = ({ newAssignment, setNewAssignment }) => {
  return (
    <div className={classNames.newAssignment3}>
      <CustomSelectBox
        title="Estimate your scope of work"
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="scope"
        dropdown={[
          {
            title: "Large",
            desc: "Longer term or complex initiatives (ex. meet monthly bookkeeping, invoicing, payroll, and tax requirements",
            value: "large",
          },
          {
            title: "Medium",
            desc: "Well-defined projects (ex. file business taxes for past tax year)",
            value: "medium",
          },
          {
            title: "Small",
            desc: "Quick and straightforward tasks (ex. get bookkeeping records up-to-date for the year)",
            value: "small",
          },
        ]}
      />
      <EachCustomCheckBox
        title="How long will your work take?"
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="length"
        dropdown={[
          "More than 6 months",
          "3 to 6 months",
          "1 to 3 months",
          "Custom",
        ]}
      />
      <CustomSelectBox
        title="What level of experience will it need?"
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="experience"
        dropdown={[
          {
            title: "Entry",
            desc: "Looking for someone relatively new to this field",
            value: "entry",
          },
          {
            title: "Intermediate",
            desc: "Looking for substantial experience in this field",
            value: "intermediate",
          },
          {
            title: "Expert",
            desc: "Looking for comprehensive and deep expertise in this field",
            value: "expert",
          },
        ]}
      />
      <CustomSelectBox
        title="Is this job a contract-to-hire opportunity? "
        info="(This helps set expectations with talent and won’t restrict who can submit proposals)"
        name="contacttohire"
        stateValue={newAssignment}
        setState={setNewAssignment}
        dropdown={[
          {
            title: "Yes, this could become full time",
            desc: "After a trial period, you can pay a one-time fee to convert the contract.",
            info: " Learn more",
            value: "true",
          },
          {
            title: "No, not at this time",
            desc: "Right now I am just looking to complete this task",
            value: "false",
          },
        ]}
      />
    </div>
  );
};

export const NewAssignment4 = ({ newAssignment, setNewAssignment }) => {
  const [priceType, setPriceType] = useState("");

  return (
    <div className={classNames.newAssignment3}>
      <CustomSelectBox
        title="Tell us your budget"
        name="billing"
        stateValue={newAssignment}
        setState={setNewAssignment}
        dropdown={[
          {
            image: clockIcon,
            title: "Hourly rate",
            value: "hourly",
          },
          {
            image: piggybankIcon,
            title: "Fixed price",
            value: "fixed",
          },
        ]}
      />
      {newAssignment?.billing === "hourly" ? (
        <>
          <CustomInputDivided
            title="Price"
            placeholder="0.00"
            secondValue="Per HR"
            name="billingamount"
            stateValue={newAssignment}
            setState={setNewAssignment}
          />
          <CustomInput
            title="Billing currency"
            placeholder="Enter currency"
            name="billingcurrency"
            stateValue={newAssignment}
            setState={setNewAssignment}
          />
        </>
      ) : newAssignment?.billing === "fixed" ? (
        <CustomInput
          title="Max price"
          placeholder="0.00"
          name="billingamount"
          stateValue={newAssignment}
          setState={setNewAssignment}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export const NewAssignment5 = ({ newAssignment, setNewAssignment }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDivClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedFile(file);
      // You can handle the file upload logic here
      console.log("Selected file:", file);
    }
  };
  return (
    <div className={classNames.newAssignment5}>
      <CustomTextarea
        title="Describe what you need"
        placeholder="Already have a description? Paste it here!"
        name="description"
        stateValue={newAssignment}
        setState={setNewAssignment}
      />
      <ul className={classNames.listContainer}>
        <div className={classNames.title}>Talent are looking for:</div>
        <li>Clear expectations about your task or deliverables</li>
        <li>The skills required for your work</li>
        <li>Good communication</li>
        <li>Details about how you to your team like to work</li>
      </ul>
      <div
        className={classNames.attachFile}
        onClick={handleDivClick}
        style={{ cursor: "pointer" }}
      >
        <input type="file" ref={fileInputRef} onChange={handleFileChange} />
        <IoIosAttach />
        <span>{selectedFile?.name ? selectedFile?.name : "Attach File"}</span>
      </div>
    </div>
  );
};

export const NewAssignment6 = ({ newAssignment, setNewAssignment }) => {
  const [localQuestions, setLocalQuestions] = useState({});

  //functions

  function addNewQuestions(event, currentQuestion) {
    setNewAssignment((prev) => {
      return {
        ...prev,
        screeningquestions:
          prev?.screeningquestions?.length > 0
            ? [...prev?.screeningquestions, currentQuestion]
            : [currentQuestion],
      };
    });
  }

  const removeQuestion = (index) => {
    setNewAssignment((prevState) => {
      const updatedQuestions = [...prevState?.screeningquestions];
      updatedQuestions.splice(index, 1); // Remove the question at the specified index
      return { ...prevState, screeningquestions: updatedQuestions };
    });
  };

  return (
    <div className={classNames.newAssignment5}>
      <CustomInput
        title="Select or add up to 5 screening questions"
        placeholder="+ Write your own question"
        name="screeningquestions"
        stateValue={localQuestions}
        setState={setLocalQuestions}
        type="clickAdd"
        func={addNewQuestions}
      />
      <div className={classNames.allQuestion}>
        {Array.isArray(newAssignment?.screeningquestions) &&
          newAssignment?.screeningquestions?.length > 0 &&
          newAssignment?.screeningquestions?.map((eachQuestion, index) => {
            return (
              <div
                key={eachQuestion + index}
                className={classNames.eachQuestion}
                onClick={() => removeQuestion(index)}
              >
                {eachQuestion}
                <div className={classNames.removeQuestion}>
                  <IoMdClose />
                </div>
              </div>
            );
          })}
      </div>

      <EachCustomCheckboxMultiple
        title="Suggested"
        options={[
          { label: "Describe your recent experience with similar projects" },
          { label: "Please list any certifications related to this project" },
          {
            label:
              "Describe your proficiency with Microsoft Excel (pivot tables, macros, etc.)",
          },
          {
            label:
              "How will you suggest improvements if you disagree with an existing process?",
          },
          { label: "How would you measure or assess a potential problem?" },
        ]}
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="screeningquestions"
      />
    </div>
  );
};

export const NewAssignment7 = ({ newAssignment, setNewAssignment }) => {
  return (
    <div className={classNames.newAssignment5}>
      <EachCustomCheckBox
        title="English level"
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="englishlevel"
        dropdown={[
          "Any level",
          "Conversational or better",
          "Fluent or better",
          "Native of bilingual only",
        ]}
      />
      <EachCustomCheckBox
        title="Hours per week"
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="hoursperweek"
        dropdown={[
          "More than 30hrs/week",
          "Less than 30hrs/week",
          "I’m not sure",
        ]}
      />
      <EachCustomCheckBox
        title="Hire date"
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="hiringrate"
        dropdown={["1 to 3 days", "One week", "Two week", "One month"]}
      />
      <EachCustomCheckBox
        title="Number of professionals needed"
        stateValue={newAssignment}
        setState={setNewAssignment}
        name="numofpeoplerequired"
        dropdown={["One person", "More than one person"]}
      />
      <CustomInput
        title="Talent type"
        placeholder="Enter talent type"
        name="talenttype"
        stateValue={newAssignment}
        setState={setNewAssignment}
      />
      <CustomInput
        title="Location"
        placeholder="Enter location"
        name="location"
        stateValue={newAssignment}
        setState={setNewAssignment}
      />
    </div>
  );
};
