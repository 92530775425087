import React, { useState } from "react";
import "./dashboardnavbar.scss";

//assets
import searchic from "../../static/images/dashboard/searchic.svg";
import downarrow from "../../static/images/dashboard/downarrow.svg";
import profile from "../../static/images/dashboard/profile.svg";

const DashboardNavbar = ({
  stateValue,
  setState,
  placeholder,
  showDrop,
  setShowDrop,
}) => {
  //   const [showDrop, setShowDrop] = useState(false);
  return (
    <div className="dash-nav">
      <div className="search-input-box" onClick={() => setShowDrop(false)}>
        <input
          className="search-input"
          type="text"
          placeholder={placeholder}
          value={stateValue}
          onChange={(e) => setState(e.target.value)}
        />
      </div>
      <div className="search-box" onClick={() => setShowDrop(false)}>
        <img className="search-icon" src={searchic} alt="" />
      </div>
      <div className="full-user" onClick={() => setShowDrop(!showDrop)}>
        <div className="user-box">
          <img
            className="user-icon"
            src={
              JSON.parse(localStorage.getItem("user"))?.user?.profile_img !==
              undefined
                ? JSON.parse(localStorage.getItem("user"))?.user?.profile_img
                : profile
            }
            alt=""
          />
        </div>
        <div
          className="arrow-box"
          style={{
            transform: showDrop ? "rotate(180deg)" : "",
            cursor: "pointer",
          }}
        >
          <img className="arrow-icon" src={downarrow} alt="" />
        </div>
      </div>
    </div>
  );
};

export default DashboardNavbar;
