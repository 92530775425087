import PreregisterBox from "../../components/PreregisterBox";
import classNames from "./landingPage.module.scss";
import NavBar from "../../components/Layouts/Navbar/navbar";
import HowItWorks from "../HowItWorks";
import WhatCanyouDo from "../WhatCanYouDo";

function LandingPage(): JSX.Element {
  return (
    <div className={classNames.landingPage}>
      <NavBar />
      <HowItWorks />
      {/* <WhatCanyouDo /> */}
    </div>
  );
}

export default LandingPage;
