import React, { createContext, useEffect, useState } from "react";
import Axios from "axios";

import allPlatforms from "./allPlatforms.svg";
// import ModalConfirm from '../components/ModalConfirm/ModalConfirm';
// import SelectCountry from '../components/SelectCountry/SelectCountry';
// import ModalSessionExpired from '../components/ModalSessionExpired/ModalSessionExpired';
// import ToastMessage from '../components/ToastMessage/ToastMessage';
// import EnterPinUnlock from '../components/EnterPinUnlock/EnterPinUnlock';
// import Cookies from 'js-cookie';
// import { APP_USER_TOKEN } from '../config';
import axios from "axios";
import { APP_CODE } from "../configs";

export const BankContext = createContext();

function BankContextProvider({ children }) {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [admin, setAdmin] = useState(false);
  const [refreshPage, setRefreshPage] = useState("");
  const [email, setEmail] = useState(localStorage.getItem("userEmail") || "");
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem("nvestBankAccessToken") || ""
  );
  const [idToken, setIdToken] = useState(
    localStorage.getItem("nvestBankIdToken") || ""
  );

  //funds page
  const [connectEA, setConnectEA] = useState("");

  // const appUserToken = Cookies.get(APP_USER_TOKEN);

  useEffect(() => {
    !admin && localStorage.setItem("nvestBankLoginAccount", email);
  }, [email, admin]);
  useEffect(() => {
    localStorage.setItem("nvestBankAccessToken", accessToken);
  }, [accessToken]);
  useEffect(() => {
    localStorage.setItem("nvestBankIdToken", idToken);
  }, [idToken]);

  const userLoginHandler = (paramEmail, paramAccessToken, paramIdToken) => {
    if (paramEmail && paramAccessToken && paramIdToken) {
      setEmail(paramEmail);
      setAccessToken(paramAccessToken);
      setIdToken(paramIdToken);
    }
    if (!paramEmail || !paramAccessToken || !paramIdToken) {
      // Cookies.remove(APP_USER_TOKEN);
    }
  };

  const [username, setUsername] = useState("");
  const [name, setName] = useState("");
  const [profileImg, setProfileImg] = useState("");
  const [profileId, setProfileId] = useState("");
  const [iceProfileId, setIceProfileId] = useState("");

  function GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    };
  }

  const [videoPlaying, setVideoPlaying] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVideoPlaying(false);
    }, 6000);
  }, []);

  useEffect(() => {
    function getUserData() {
      Axios.post("https://comms.globalxchange.io/get_affiliate_data_no_logs", {
        email: email,
      }).then((res) => {
        const data = res.data[0];
        if (data) {
          setUsername(data.username);
          setName(data.name);
          setProfileImg(data.profile_img);
        }
      });
      if (email) {
        Axios.get(
          `https://comms.globalxchange.io/user/details/get?email=${email}`
        ).then((res) => {
          const { data } = res;
          if (data.status) {
            setProfileId(data.user[`TaxChains_profile_id`]);
            setIceProfileId(data.user.ice_profile_id);
          }
        });
      }
    }
    if (email && idToken) {
      Axios.post("https://comms.globalxchange.io/coin/verifyToken", {
        email,
        token: idToken,
      }).then((res) =>
        res.data.status || admin || res.data.login
          ? getUserData()
          : userLoginHandler("", "", "")
      );
    }
  }, [email, idToken]);

  useEffect(() => {
    if (email) {
      axios
        .get(
          `https://comms.globalxchange.io/gxb/product/license/note/get/all?email=${email}`
        )
        .then((response) => {
          // console.log(response?.data?.data, "connect ea's");
          setConnectEA(response?.data?.data);
        })
        .catch((error) => {
          console.log(error?.message, "connnect EA's");
        });
    }
  }, [email]);

  const [toastShow, setToastShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastTitle, setToastTitle] = useState("");
  const tostShowOn = (message, title) => {
    setToastShow(true);
    setToastMessage(message);
    setToastTitle(title);
    setTimeout(() => {
      setToastShow(false);
    }, 3000);
  };

  const [ratesRes, setRatesRes] = useState([]);
  const [liquidRates, setLiquidRates] = useState([]);
  useEffect(() => {
    if (idToken && email) {
      Axios.get(
        "https://comms.globalxchange.io/coin/vault/earnings/getinterestrates"
      ).then((res) => {
        const { data } = res;
        if (data.status) {
          setRatesRes(data.rates);
        }
      });
      if (email) {
        Axios.get(
          "https://comms.globalxchange.io/coin/iced/get/liquid/interest"
        ).then((res) => {
          const { data } = res;
          if (data.status) {
            const { interest_rates } = data;
            setLiquidRates(interest_rates);
          }
        });
      }
    }
  }, []);

  const [coinList, setCoinList] = useState([]);
  const updateBalance = () => {
    Axios.post("https://comms.globalxchange.io/coin/vault/service/coins/get", {
      app_code: APP_CODE,
      profile_id: profileId,
    }).then((res) => {
      const { data } = res;
      if (data.status) {
        const { coins_data } = data;
        coins_data.sort(GetSortOrder("type"));
        setCoinList(coins_data);
      }
    });
  };
  useEffect(() => {
    if (email) {
      updateBalance();
    }
  }, [profileId, refreshPage.balanceUpdate]);

  const [icedContracts, setIcedContracts] = useState([]);
  function getIcedContracts() {
    email &&
      Axios.get(
        `https://comms.globalxchange.io/coin/iced/contract/get?email=${email}`
      ).then((res) => {
        const { data } = res;
        if (data.status) {
          const { icedContracts } = data;
          setIcedContracts(icedContracts);
        }
      });
  }
  useEffect(() => {
    getIcedContracts();
    // eslint-disable-next-line
  }, [email, refreshPage.balanceUpdate]);

  const [coinListObject, setCoinListObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    coinList.forEach((coin) => {
      coinObj[coin.coinSymbol] = coin;
    });
    setCoinListObject(coinObj);
  }, [coinList]);

  const [coinNameObject, setCoinNameObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    coinList.forEach((coin) => {
      coinObj[coin.coinName] = coin;
    });
    setCoinNameObject(coinObj);
  }, [coinList]);

  const [liquidRatesObject, setLiquidRatesObject] = useState({});
  useEffect(() => {
    let coinObj = {};
    liquidRates.forEach((coin) => {
      coinObj[coin.coin] = coin;
    });
    setLiquidRatesObject(coinObj);
  }, [liquidRates]);

  const [openDefaultCoinSidebar, setOpenDefaultCoinSidebar] = useState(false);
  const [defaultCoin, setDefaultCoin] = useState({
    coin: null,
    name: "Default Coin",
    img: allPlatforms,
  });
  const convertCoin = (amount, coin) => {
    if (defaultCoin.coin && defaultCoin.coin !== null) {
      return (
        (amount * coinListObject[coin].price.USD) /
        coinListObject[defaultCoin.coin].price.USD
      );
    } else {
      return amount;
    }
  };

  const [footerShow, setFooterShow] = useState(true);

  const [updateInterval, setUpdateInterval] = useState(5);

  // Modal Variables
  const [openModal, setOpenModal] = useState(false);
  const [onClose, setOnClose] = useState(() => {});
  const [onConfirm, setOnConfirm] = useState(() => {});
  const [modalText, setModalText] = useState("");
  const [trackerSidebarMenu, setTrackerSidebarMenu] = useState(false);

  const populateModal = (text, onCloseParam, onConfirmParam) => {
    setOpenModal(true);
    setOnClose(() => onCloseParam);
    setOnConfirm(() => onConfirmParam);
    setModalText(text);
  };

  // To Populate List In Sidebar
  const [contentSideBar, setContentSideBar] = useState({});

  // Coin Data For Coin Detail
  const [coinData, setCoinData] = useState({});

  useEffect(() => {
    if (email) {
      getAllCoins();
    }
    return () => {};
  }, []);

  function getAllCoins() {
    Axios.get("https://comms.globalxchange.io/coin/vault/get/all/coins").then(
      (res) => {
        const { data } = res;
        if (data.status) {
          let coinObj = {};
          data.coins.forEach((coin) => {
            coinObj[coin.coinSymbol] = coin;
          });
          setCoinData(coinObj);
        }
      }
    );
  }

  const [conractsObj, setConractsObj] = useState({});
  useEffect(() => {
    if (email) {
      Axios.get("https://comms.globalxchange.io/coin/iced/admin/get/data").then(
        (res) => {
          const { data } = res;
          if (data.status) {
            const obj = {};
            data.config_data.forEach((config) => {
              obj[config.coin] = { ...obj[config.coin], ...config };
            });
            setConractsObj(obj);
          }
        }
      );
    }
  }, []);

  const [modalSessionExpOpen, setModalSessionExpOpen] = useState(false);
  const validateToken = async (paramEmail, paramToken) => {
    if (admin) return true;
    const res = await Axios.post(
      "https://comms.globalxchange.io/coin/verifyToken",
      {
        email: paramEmail,
        token: paramToken,
      }
    );
    if (res.data && res.data.status) {
      return true;
    } else {
      setModalSessionExpOpen(true);
      return false;
    }
  };

  const [bondDetail, setBondDetail] = useState();
  const [chatOn, setChatOn] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  const [sidebarCollapse, setSidebarCollapse] = useState(false);
  const [inboxOpen, setInboxOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  useEffect(() => {
    if (videoShow) {
      setChatOn(false);

      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [videoShow]);
  useEffect(() => {
    if (chatOn) {
      setVideoShow(false);

      setSidebarCollapse(true);
      setInboxOpen(false);
    }
  }, [chatOn]);

  useEffect(() => {
    if (inboxOpen) {
      setVideoShow(false);
      setChatOn(false);
      setSidebarCollapse(true);
    }
  }, [inboxOpen]);

  useEffect(() => {
    if (!sidebarCollapse) {
      setVideoShow(false);
      setChatOn(false);

      setInboxOpen(false);
    }
  }, [sidebarCollapse]);

  useEffect(() => {
    if (settingsOpen) {
      setChatOn(true);
    }
  }, [settingsOpen]);

  function closeSidebars() {
    setVideoShow(false);
    setChatOn(false);
    setInboxOpen(false);
  }
  const [defaultPrecission, setDefaultPrecission] = useState(0);
  const [tempMail, setTempMail] = useState("");
  const [tempPass, setTempPass] = useState("");

  function copyToClipboard(value, title) {
    navigator.clipboard.writeText(value).then(() => {
      tostShowOn(value, title);
    });
  }
  function justCopyToClipboard(value, title) {
    navigator.clipboard.writeText(value);
  }

  const [enterPin, setEnterPin] = useState(false);
  const [selectedTopItemReact, setSelectedTopItemReact] = useState("Retire");

  const [selectedbtn, setSelectedbtn] = useState("asset");
  const [bondcount, setBondcount] = useState("");

  //username
  const [linkname, setLinkname] = useState("");

  const [bankAccountId, setBankAccountId] = useState("");

  //onhold
  const [userSelectedOnHold, setUserSelectedOnHold] = useState("");

  //home page
  const [pin, setPin] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchDirectory, setSearchDirectory] = useState("");

  return (
    <BankContext.Provider
      value={{
        userLoginHandler,
        email,
        token: idToken,
        accessToken,
        tostShowOn,
        username,
        name,
        profileImg,
        ratesRes,
        coinList,
        profileId,
        liquidRates,
        liquidRatesObject,
        coinListObject,
        openDefaultCoinSidebar,
        setOpenDefaultCoinSidebar,
        defaultCoin,
        setDefaultCoin,
        convertCoin,
        icedContracts,
        footerShow,
        setFooterShow,
        updateInterval,
        setUpdateInterval,
        populateModal,
        contentSideBar,
        setContentSideBar,
        coinData,
        conractsObj,
        coinNameObject,
        getIcedContracts,
        validateToken,
        updateBalance,
        chatOn,
        setChatOn,
        admin,
        setAdmin,
        setEmail,
        setIdToken,
        setUsername,
        setName,
        setProfileImg,
        setProfileId,
        refreshPage,
        setRefreshPage,
        videoShow,
        setVideoShow,
        sidebarCollapse,
        setSidebarCollapse,
        inboxOpen,
        setInboxOpen,
        defaultPrecission,
        setDefaultPrecission,
        settingsOpen,
        setSettingsOpen,
        closeSidebars,
        bondDetail,
        setBondDetail,
        tempMail,
        setTempMail,
        tempPass,
        setTempPass,
        iceProfileId,
        copyToClipboard,
        justCopyToClipboard,
        setEnterPin,
        // appUserToken,
        selectedTopItemReact,
        setSelectedTopItemReact,

        //createbond - stakingvault in defi page
        selectedbtn,
        setSelectedbtn,
        bondcount,
        setBondcount,
        linkname,
        setLinkname,
        //tracker
        trackerSidebarMenu,
        setTrackerSidebarMenu,
        bankAccountId,
        setBankAccountId,

        //funds page
        connectEA,
        setConnectEA,

        //onhold
        userSelectedOnHold,
        setUserSelectedOnHold,

        //homepage
        pin,
        setPin,
        searchTerm,
        setSearchTerm,
        searchDirectory,
        setSearchDirectory,
      }}
    >
      {children}
      {/* {videoPlaying ? (
        <div className="firstVideo">
          <img src={loadImg} alt="" />
        </div>
      ) : (
        ''
      )} */}
      {/* {openModal ? (
        <ModalConfirm
          onClose={onClose}
          onConfirm={onConfirm}
          text={modalText}
          setOpenModal={setOpenModal}
        />
      ) : (
        ''
      )} */}
      {/* <SelectCountry /> */}
      {/* {modalSessionExpOpen ? (
        <ModalSessionExpired
          onClose={() => {
            setModalSessionExpOpen(false);
          }}
        />
      ) : (
        ''
      )} */}
      {/* {toastShow && (
        <ToastMessage textTwo={toastMessage} textOne={toastTitle} />
      )} */}
      {/* {enterPin && (
        <EnterPinUnlock
          onSucces={() => {
            setAdmin(true);
            setEnterPin(false);
          }}
          onClose={() => setEnterPin(false)}
        />
      )} */}
    </BankContext.Provider>
  );
}

export default BankContextProvider;
