import React, { useContext, useEffect, useState } from "react";
import classNames from "./allbids.module.scss";
import axios from "axios";
import { BankContext } from "../../context/Context";
import VaultsContentLoading from "../Vaults/components/VaultsContentLoading";
import closepop from "../../static/images/dashboard/closepop.svg";
import CustomInput, { CustomTextarea } from "../../components/Custom";
import { toast } from "react-toastify";
import { useStore } from "../../components/store/store";

const AllBids = ({
  searchedValue,
  localRefresh,
  setLocalRefresh,
  API,
  sidebar,
  from,
  setCurrentBidId,
  setOpenRight,
}) => {
  const { email } = useContext(BankContext);
  const { setsideNav, setaccsideNav } = useStore();
  const [allBids, setAllBids] = useState([]);
  const [allBidsLoading, setAllBidsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [createOffer, setCreateOffer] = useState({});

  //functions

  async function getAllBids() {
    setAllBidsLoading(true);
    try {
      let response = await axios.get(
        from === "userDashboard"
          ? API + (email ? email : localStorage.getItem("userEmail"))
          : API
      );
      if (
        Array.isArray(response?.data?.data) &&
        response?.data?.data?.length > 0
      ) {
        setAllBids(response?.data?.data);
      }
      // console.log(response, "Get all Assignments response");
      setAllBidsLoading(false);
    } catch (error) {
      setAllBidsLoading(false);
      console.log(error?.message, "Get all Assignments error");
    }
  }

  const filteredData = (item) =>
    item
      ? Object.values(item)
          ?.join(" ")
          ?.toLowerCase()
          ?.includes(searchedValue?.toLowerCase())
      : [];

  async function createOfferForBid() {
    try {
      let response = await axios.post(
        "https://accountants.apimachine.com/offerbid/add",
        {
          ...createOffer,
          bidid: isSidebarOpen?.bid?.bidid,
          email: localStorage.getItem("userEmail")
            ? localStorage.getItem("userEmail")
            : email
            ? email
            : "",
        }
      );
      console.log(response, "Create bid from offer response");
      if (response?.data.status) {
        toast("Added offer bid");
        setIsSidebarOpen(false);
        setLocalRefresh((prev) => !prev);
      } else {
        toast.error("Error while creating offer bid!");
      }
    } catch (error) {
      console.log(error.message, "Create offer for bid error");
    }
  }

  //renderings

  useEffect(() => {
    if (email || localStorage.getItem("userEmail")) {
      getAllBids();
    }
  }, [localRefresh, API, email]);

  return (
    <div className={classNames.allBids}>
      <div className={classNames.headTab}>
        <div>Name</div>
        <div>Creation Date</div>
        <div>Description</div>
        <div>Status</div>
      </div>
      <div className={classNames.bodyContainer}>
        {allBidsLoading || allBids?.length < 1
          ? Array.from({ length: 4 }).map((_, index) => {
              return <VaultsContentLoading key={"vaults loading " + index} />;
            })
          : Array.isArray(allBids) && allBids?.length > 0
          ? allBids
              .filter((eachBid) => filteredData(eachBid))
              .map((eachBid) => {
                return (
                  <div
                    className={classNames.bidsContent}
                    key={eachBid?._id}
                    onClick={() => {
                      if (sidebar?.status) {
                        setIsSidebarOpen({ step: "step1", bid: eachBid });
                      }
                      if (from === "userDashboard" || from === "accDashboard") {
                        setOpenRight(true);
                        setCurrentBidId(eachBid?.bidid);
                        if (from === "userDashboard") {
                          setsideNav("EachAssignment");
                        } else if (from === "accDashboard") {
                          setaccsideNav("EachAssignment");
                        }
                      }
                    }}
                  >
                    <div>{eachBid?.name}</div>
                    <div>{eachBid?.createdAt}</div>
                    <div>{eachBid?.description}</div>
                    <div>{eachBid?.status}</div>
                  </div>
                );
              })
          : ""}
      </div>
      {sidebar?.status && (
        <>
          {isSidebarOpen?.step === "step1" ? (
            <div
              className={classNames.sidebarContainer}
              style={{ right: isSidebarOpen?.step ? "" : "-500px" }}
            >
              <div className={classNames.title}>
                <span>Bid Actions</span>
                <div
                  className={classNames.closeBtn}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <img className="acc-popular-img" src={closepop} alt="" />
                </div>
              </div>
              <div className={classNames.sidebarContent}>
                <div
                  className={classNames.eachContent}
                  onClick={() =>
                    setIsSidebarOpen((prev) => {
                      return { ...prev, step: "step2makeOffer" };
                    })
                  }
                >
                  Make offer
                </div>
                <div className={classNames.eachContent}>See offers</div>
              </div>
            </div>
          ) : isSidebarOpen?.step === "step2makeOffer" ? (
            <div
              className={classNames.sidebarContainer}
              style={{ right: isSidebarOpen?.step ? "" : "-500px" }}
            >
              <div className={classNames.title}>
                <span>Make offer</span>
                <div
                  className={classNames.closeBtn}
                  onClick={() => setIsSidebarOpen(false)}
                >
                  <img className="acc-popular-img" src={closepop} alt="" />
                </div>
              </div>
              <div className={classNames.sidebarContent}>
                <CustomInput
                  placeholder="Amount"
                  name="amount"
                  setState={setCreateOffer}
                  stateValue={createOffer}
                />
                <CustomTextarea
                  placeholder="Notes"
                  name="notes"
                  setState={setCreateOffer}
                  stateValue={createOffer}
                />
                <div className={classNames.btnsContainer}>
                  <button
                    className={classNames.submitBtn}
                    onClick={createOfferForBid}
                  >
                    Submit Offer
                  </button>
                  <button
                    className={classNames.transparentBtn}
                    onClick={() =>
                      setIsSidebarOpen((prev) => {
                        return { ...prev, step: "step1" };
                      })
                    }
                  >
                    Go Back
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default AllBids;
